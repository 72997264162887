<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()" />
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatNumber2Digit, formatNumber} from "./assets/utils";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  export default {
    props: ['staff', 'bookLists', 'org', 'startDate', 'endDate'],
    components: {
      jsPDF,
    },
    name: "P32Day",
    data() {
      return {
        villageNo: '4',
        nameVillageNo: 'บ้านเหล่าคราม(04)',
        area: '04'
      }
    },
    methods: {
      async getBookLists() {
        this.$vs.loading();
        let payload = {
          startDate: moment(this.startDate).subtract(1,'day').format("YYYY/MM/DD"),
          endDate: moment(this.endDate).format("YYYY/MM/DD")
        };
        await this.$store.dispatch("bookLists/getBookListByP32Report", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            this.$vs.loading.close();
            const booklists = res.data;
            this.generatePDF(booklists);
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `สรุปรายงาน`,
          text: 'ไม่พบข้อมูลในรายงาน',
          acceptText: 'ปิด'
        })
      },
      setDateFormat(value) {
        try {
          if(value !== '' && value !== undefined && value !== 'Invalid date' && value !== 'NaN'){
            const dateNow = moment(value).format("DD/MM/YYYY");
            if(dateNow === 'Invalid date') {
              return '-'
            } else {
              const dateSplit = dateNow.split("/");
              const year = parseInt(dateSplit[2]) + 543;
              const date = dateSplit[0] + "/" + dateSplit[1] + "/" + year;
              return date;
            }
          } else {
            return '-'
          }
        }catch (e) {
          return value
        }
      },
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      },
      checkData() {
        if (this.bookLists.length === 0) {
          this.$vs.dialog({
            type: 'alert',
            color: 'danger',
            title: `ผิดพลาด`,
            text: 'ไม่มีรายชื่อผู้ที่ชำระเงินแล้ว',
            acceptText: 'ปิด'
          })
        } else {
          this.generatePDF();
        }
      },
      dateFormat(value) {
        if (value && value !== '-') {
          const datetime = new Date(value);
          datetime.setFullYear(datetime.getFullYear());
          return moment(datetime).tz('Asia/Bangkok').format('DD/MM/YYYY');
        }
        return value;
      },
      generatePDF(bookListData) {
        const title = this.staff;
        const doc = new jsPDF({format: [279, 210], unit: 'mm', orientation: 'portrait',});
        let bookLists = bookListData;
        const org = this.org;
        const dateStart = moment(this.startDate).format('DD/MM/YYYY');
        const dateStartSplit = dateStart.split('/');
        const startYear = parseInt(dateStartSplit[2]) + 543;
        const startDate = dateStartSplit[0] + '/' + dateStartSplit[1] + '/' + startYear;
        const dateEnd = moment(this.endDate).format('DD/MM/YYYY');
        const dateEndSplit = dateEnd.split('/');
        const endYear = parseInt(dateEndSplit[2]) + 543;
        const endDate = dateEndSplit[0] + '/' + dateEndSplit[1] + '/' + endYear;
        const date = this.currentThaiDate()
        let BA11Sum = 0;
        let BA12Sum = 0;
        let BA13Sum = 0;
        let BA14Sum = 0;
        let BA15Sum = 0;
        let allSum = 0;
        let sumBA11 = 0;
        let sumBA12 = 0;
        let sumBA13 = 0;
        let sumBA14 = 0;
        let sumBA15 = 0;
        let array = [];
        bookLists.forEach(function (key) {
          let vat = 0.0
          if(parseFloat(key['BA13']) !== 0){
            vat = (parseFloat(formatNumber(key['BA11'])) + parseFloat(formatNumber(key['BA12']))) * 0.07
          }
          sumBA11 = sumBA11 + parseFloat(key['BA11'])
          sumBA12 = sumBA12 + parseFloat(key['BA12'])
          sumBA13 = sumBA13 + vat
          sumBA14 = sumBA14 + parseFloat(key['BA14'])
          sumBA15 = sumBA15 + parseFloat(key['BA15'])
          allSum = allSum + parseFloat(key['total'])
        });

        for (let i = 0; i < bookLists.length; i++) {
          const date = this.setDateFormat(bookLists[i].date);
          array.push([
            i + 1,
            date,
            formatNumber(bookLists[i].BA11),
            formatNumber(bookLists[i].BA12),
            formatNumber(bookLists[i].BA13),
            formatNumber(bookLists[i].BA14),
            formatNumber(bookLists[i].BA15),
            formatNumber(bookLists[i].total),
          ]);
          BA11Sum += parseFloat(bookLists[i].BA11);
          BA12Sum += parseFloat(bookLists[i].BA12);
          BA13Sum += parseFloat(bookLists[i].BA13);
          BA14Sum += parseFloat(bookLists[i].BA14);
          BA15Sum += parseFloat(bookLists[i].BA15);
          allSum += parseFloat(bookLists[i].total);
        }
        let sumBA111213 = BA11Sum + BA12Sum + BA13Sum + BA14Sum + BA15Sum;
        array.push([
          ' ',
          'รวมทั้งสิ้น',
          formatNumber(BA11Sum),
          formatNumber(BA12Sum),
          formatNumber(BA13Sum),
          formatNumber(BA14Sum),
          formatNumber(BA15Sum),
          formatNumber(sumBA111213),
        ]);
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'bold');
        doc.setFontSize(12);
        doc.autoTable({
          styles: {font: 'THSarabunNew', fontSize: 12},
          margin: {left: 8, top: 40, bottom: 20},
          theme: 'plain',
          head: [['ลำดับที่', 'วันที่ชำระเงิน', 'ค่าน้ำ', 'ค่าธรรมเนียม', 'ภาษี', 'ค่าบำบัดฯ', 'ค่าขยะ', 'รวมทั้งสิ้น']],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFontSize(18);
            doc.text(org.Org01, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(org.Org03, doc.internal.pageSize.getWidth() / 2, 18, {align: 'center'});
            doc.setFontSize(16);
            doc.text('รายงาน ป.32 แยกตามวันที่ ' + startDate + ' - ' + endDate, doc.internal.pageSize.getWidth() / 2, 28, {align: 'center'});
          },
        });
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        doc.save('รายงานป.32ประจำวัน-' + moment().format('DD-MM-YYYY') + '.pdf');
      }
    },
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>

