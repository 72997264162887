<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-3">
      <vx-card title="บัญชี/รอบบันทึกค่าบริการ">
        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ชำระเงินช่วงวันที่:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                  :disabled-dates="disabledInvoiceDates"
                  :language="languages[language]"
                  format="dd MMMM yyyy"
                  v-model="start_date"
                ></datepicker>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ถึง:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                  :disabled-dates="disabledInvoiceDates"
                  :language="languages[language]"
                  format="dd MMMM yyyy"
                  v-model="end_date"
                ></datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col md:w-1/3 sm:w-1/4 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เลือกพื้นที่/กลุ่ม</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select @input="setArea" :options="areas" :value="areas" :clearable="false" v-model="selectedArea"/>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <div id="data-list-list-view" class="vx-col w-full mb-base data-list-container">
      <vs-table :data="[]">
        <template slot="thead">
          <vs-th>ลำดับที่</vs-th>
          <vs-th>ชื่อรายงาน</vs-th>
          <vs-th>พิมพ์รายงาน</vs-th>
        </template>
        <template>
          <vs-tr>
            <vs-td><p class="product-name font-medium">1</p></vs-td>
            <vs-td><p class="product-name font-medium">พิมพ์รายงานแยกตามวันที่</p></vs-td>
            <vs-td class="whitespace-no-wrap">
              <P32DailyReport :startDate="start_date" :endDate="end_date" :org="org"/>
              <P32DayByDateDailyExcelReport :startDate="start_date" :endDate="end_date" :org="org"/>
            </vs-td>
          </vs-tr>
<!--          <vs-tr>-->
<!--            <vs-td><p class="product-name font-medium">2</p></vs-td>-->
<!--            <vs-td><p class="product-name font-medium">พิมพ์รายงานแยกตามวันที่แต่ละเขต</p></vs-td>-->
<!--            <vs-td class="whitespace-no-wrap">-->
<!--              <P32AreaDailyReport :startDate="start_date" :endDate="end_date" :org="org"/>-->
<!--              <P32DayByDateAreaDailyExcelReport :startDate="start_date" :endDate="end_date" :org="org"/>-->
<!--            </vs-td>-->
<!--          </vs-tr>-->
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>

  import vSelect from "vue-select";
  import moduleConfig from "@/store/config/moduleConfig.js";
  import moduleBillConfig from "@/store/billconfig/moduleBillConfig.js";
  import moduleArea from "@/store/area/moduleArea.js";
  import P32DailyReport from "../pdf/P32DayByDateDailyReport.vue";
  import P32AreaDailyReport from "../pdf/P32DayByDateAreaDailyReport.vue";
  import P32DayByDateDailyExcelReport from "../pdf/P32DayByDateDailyExcelReport.vue";
  import P32DayByDateAreaDailyExcelReport from "../pdf/P32DayByDateAreaDailyExcelReport.vue";
  import * as XLSX from 'xlsx';
  import Datepicker from "vuejs-datepicker";
  import * as lang from "vuejs-datepicker/src/locale";
  import moment from "moment";

  export default {
    components: {
      Datepicker,
      vSelect,
      P32DailyReport,
      P32AreaDailyReport,
      P32DayByDateAreaDailyExcelReport,
      P32DayByDateDailyExcelReport
    },
    data() {
      return {
        disabledInvoiceDates: {
          from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-1) ,
        },
        areaSelected: false,
        start_date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() -1),
        end_date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() -1),
        language: "th",
        languages: lang,
        selectedArea: {label: '', value: ''},
        selectedBillConfig: {label: '', value: '', data: ''},
        year: '',
        yearth: '',
        imgSignBase64: '',
        imgLogoBase64: '',
        baseUrl: 'http://188.166.177.25/',
      }
    },
    computed: {
      org() {
        return this.$store.state.config.item;
      },
      areas() {
        let options = [];
        options.push({
          label: 'ทั้งหมด',
          value: 'all',
          data: this.$store.state.area.items
        });
        this.$store.state.area.items.forEach(function (item) {
          options.push({
            label: item.A02,
            value: item.A01
          });
        });

        return options;
      },
      billConfigs() {
        let options = [];
        this.$store.state.billConfigs.items.forEach(function (item) {
          options.push({
            label: item.CB01  + ' (' + item.CB04+ ')',
            value: item.CB01,
            data: item
          })
        });
        return options;
      },
    },
    methods: {
      setArea(){
        this.areaSelected = true
      },
      async init() {
        const area = await this.$store.state.area.items[0];
        const bill = await this.$store.state.billConfigs.items[0];
        this.selectedArea = {
          label: 'ทั้งหมด',
          value: 'all',
        };
        this.selectedBillConfig = {
          label: bill.CB01  + ' (' + bill.CB04+ ')',
          value: bill.CB01,
          data: bill
        };
        this.year = bill.CB03;
        this.yearth = bill.CB04;
        const url = await this.$store.state.config.item.report_image_url;
        if(url !== '' && url !== undefined && url !== null) {
          this.imgSignBase64 = await this.getDataUri(this.baseUrl + url);
        }
        const logoUrl = await this.$store.state.config.item.logo_image_url;
        if(logoUrl !== '' && logoUrl !== undefined && logoUrl !== null) {
          this.imgLogoBase64 = await this.getDataUri(this.baseUrl + logoUrl);
        }
        this.$vs.loading.close();
      },
      getDataUri(url) {
        return new Promise(resolve => {
          const image = new Image();
          image.setAttribute('crossOrigin', 'anonymous');
          image.onload = function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(this, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            resolve(canvas.toDataURL("image/png"));
          };
          image.src = url;
        })
      },
      setBillConfigs(item) {
        this.year = item.data.CB03;
        this.yearth = item.data.CB04;
      },
      excel() {
        var itemsFormatted = [];
        for (let i = 0; i < 100; i++) {
          itemsFormatted.push({
            'ลำดับที่': i,
            'เลขที่ผู้ใช้น้ำ': '01010126',
            'เลขมาตรวัด': '501009794',
            'ชื่อ-สกุลผู้ใช้น้ำ': 'นายวินัย เพชรอุไร',
            'เลขที่บัญชี': '929-0-18320-9',
            'ชื่อบัญชี': 'น.ส.ศศิวิมล เพชรอุไร',
            'เดือนค้างชำระ': this.yearth,
            'เลขที่บิล': '63-116',
            'รวมยอดค้าง': '32.10'
          });
        }
        var fileName = 'หักผ่านธนาคาร.xlsx';
        var ws = XLSX.utils.json_to_sheet(itemsFormatted);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "People");
        XLSX.writeFile(wb, fileName);
      }
    },
    async created() {
      this.$vs.loading();
      if (!moduleBillConfig.isRegistered) {
        this.$store.registerModule('billConfigs', moduleBillConfig)
        moduleBillConfig.isRegistered = true
      }
      if (!moduleArea.isRegistered) {
        this.$store.registerModule('area', moduleArea);
        moduleArea.isRegistered = true
      }
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule('config', moduleConfig)
        moduleConfig.isRegistered = true
      }

      await this.$store.dispatch("billConfigs/getDataListMenuItems");
      await this.$store.dispatch("area/fetchDataListItems");
      await this.$store.dispatch("config/fetchDataItem");
      setTimeout(() => {
        this.$emit('input', this.init())
      }, 1000)
    },
  }
</script>

<style lang="scss">
  #data-list-list-view {
    .vs-con-table {

      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table--not-data {
        display: none;
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
