<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="getBookLists()" />
</template>

<script>
  import * as XLSX from 'xlsx';
  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatNumber2Digit, formatNumber} from "./assets/utils";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  export default {
    props: ['staff', 'bookLists', 'org', 'startDate', 'endDate'],
    components: {
      XLSX,
    },
    name: "P32Day",
    data() {
      return {
        villageNo: '4',
        nameVillageNo: 'บ้านเหล่าคราม(04)',
        area: '04'
      }
    },
    methods: {
      async getBookLists() {
        this.$vs.loading();
        let payload = {
          startDate: moment(this.startDate).subtract(1,'day').format("YYYY/MM/DD"),
          endDate: moment(this.endDate).format("YYYY/MM/DD")
        };
        await this.$store.dispatch("bookLists/getBookListByP32Report", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            this.$vs.loading.close();
            const booklists = res.data;
            this.generatePDF(booklists);
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `สรุปรายงาน`,
          text: 'ไม่พบข้อมูลในรายงาน',
          acceptText: 'ปิด'
        })
      },
      setDateFormat(value) {
        try {
          if(value !== '' && value !== undefined && value !== 'Invalid date' && value !== 'NaN'){
            const dateNow = moment(value).format("DD/MM/YYYY");
            if(dateNow === 'Invalid date') {
              return '-'
            } else {
              const dateSplit = dateNow.split("/");
              const year = parseInt(dateSplit[2]) + 543;
              const date = dateSplit[0] + "/" + dateSplit[1] + "/" + year;
              return date;
            }
          } else {
            return '-'
          }
        }catch (e) {
          return value
        }
      },
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      },
      checkData() {
        if (this.bookLists.length === 0) {
          this.$vs.dialog({
            type: 'alert',
            color: 'danger',
            title: `ผิดพลาด`,
            text: 'ไม่มีรายชื่อผู้ที่ชำระเงินแล้ว',
            acceptText: 'ปิด'
          })
        } else {
          this.generatePDF();
        }
      },
      dateFormat(value) {
        if (value && value !== '-') {
          const datetime = new Date(value);
          datetime.setFullYear(datetime.getFullYear());
          return moment(datetime).tz('Asia/Bangkok').format('DD/MM/YYYY');
        }
        return value;
      },
      generatePDF(bookListData) {
        let bookLists = bookListData;
        let array = [];
        for (let i = 0; i < bookLists.length; i++) {
          const date = this.setDateFormat(bookLists[i].date);
          let vat = 0.0
          if(parseFloat(bookLists[i].BA13) !== 0){
            vat = (parseFloat(formatNumber(bookLists[i].BA11)) + parseFloat(formatNumber(bookLists[i].BA12))) * 0.07
          }
          array.push([
            i + 1,
            date,
            formatNumber(bookLists[i].BA11),
            formatNumber(bookLists[i].BA12),
            formatNumber(vat),
            formatNumber(bookLists[i].BA14),
            formatNumber(bookLists[i].BA15),
            formatNumber(bookLists[i].total),
          ]);
        }
        let fileName = 'รายงานป.32ประจำวัน.xlsx';
        let ws = XLSX.utils.json_to_sheet(array);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, fileName);
        XLSX.writeFile(wb, fileName);
      }
    },
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>

